import React, { useState, useEffect, createContext } from 'react';
import _get from 'lodash/get';
import { isPast, parse } from 'date-fns';

import { client } from 'cccisd-apollo';
import axios from 'cccisd-axios';
import IconHome from 'cccisd-icons/home';
import Loader from 'cccisd-loader';
import { Link, useLocation } from 'cccisd-react-router';
import RenderInBody from 'cccisd-render-in-body';
import { Html } from 'cccisd-wysiwyg';

import query from './licenseStatus.graphql';
import style from './style.css';

export const LicensingContext = createContext({
    licensingData: {
        isLoading: true,
        isValid: false,
    },
    groupingUnitData: {
        currentTimepoint: -1,
        showK5: false,
        show68: false,
        show912: false,
        deployments: [],
    },
});

const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd.fortress;

const LicensingWrapper = props => {
    const pawnId = Fortress.user.acting.id;
    const actingRoleHandle = Fortress.user.acting.role.handle;

    const location = useLocation();
    const [noLicenseModal, setNoLicenseModal] = useState(false);
    const [groupingUnitData, setGroupingUnitData] = useState({});
    const [licensingData, setLicensingData] = useState({
        isLoading: true,
        isValid: false,
        noLicenseMessage: '',
    });

    async function fetchData() {
        const resp = await client.query({
            query,
            fetchPolicy: 'network-only',
        });

        const districtData =
            actingRoleHandle === 'uberadmin'
                ? { showK5: true, show68: true, show912: true, currentTimepoint: 1 }
                : _get(resp, 'data.roles.anyRole.ancestorGroups.groupingUnit.fields', {}) || {};
        setGroupingUnitData({
            ...districtData,
            currentTimepoint: districtData.currentTimepoint || 1,
            deployments: _get(resp, 'data.flows.deploymentList', []) || [],
        });

        const rolesThatNeedLicense = (_get(resp, 'data.products.product.roleList', []) || []).map(r => r.handle);
        if (!rolesThatNeedLicense.includes(actingRoleHandle)) {
            // Don't require a license, so let them see it
            return setLicensingData({
                isLoading: false,
                isValid: true,
            });
        }

        const productId = _get(resp, 'data.products.product.productId');
        if (!productId) {
            // Product must not be set up yet in the UI, so just let them see it anyway
            return setLicensingData({
                isLoading: false,
                isValid: true,
            });
        }

        const noLicenseNoneAvailable = _get(
            resp,
            'data.products.product.settings.customNotifications.noLicenseNoInherit',
            'No License available at this time.'
        );
        const licenseExpiredNoneAvailable = _get(
            resp,
            'data.products.product.settings.customNotifications.expiredNoInherit',
            'Your license to this product has expired.'
        );

        const pawn = resp.data.roles.anyRole;
        const owned = pawn.ownedLicenseList.find(ownedLicense => ownedLicense.product.productId === productId);
        if (owned && !owned.isExpired) {
            return setLicensingData({
                isLoading: false,
                isValid: true,
            });
        }

        const availableToConsume = pawn.availableLicenseList.find(available => {
            if (
                available?.settings?.defaultExpirationSelection === 'date' &&
                available?.settings?.defaultExpirationValueDate &&
                isPast(parse(available?.settings?.defaultExpirationValueDate))
            ) {
                return false;
            }
            return available.product.productId === productId;
        });

        if (
            owned &&
            owned.isExpired &&
            availableToConsume &&
            availableToConsume.quantity > 0 &&
            availableToConsume.inheritable
        ) {
            const renewLicenseResp = await axios.put(
                Boilerplate.route('licensing.api.license.updateOwned', { license: owned.licenseId }),
                {
                    action: 'renew',
                    groupId: availableToConsume.groupId,
                }
            );

            if (renewLicenseResp?.data?.status !== 'success') {
                return setLicensingData({
                    isLoading: false,
                    isValid: false,
                    noLicenseMessage: 'License has expired, and we could not renew the license at this time.',
                });
            }

            return setLicensingData({
                isLoading: false,
                isValid: true,
            });
        }

        if (owned) {
            return setLicensingData({
                isLoading: false,
                isValid: false,
                noLicenseMessage: licenseExpiredNoneAvailable,
            });
        }

        if (availableToConsume && availableToConsume.inheritable && availableToConsume.quantity > 0) {
            const newLicenseResponse = await axios.post(Boilerplate.route('licensing.api.license.create'), {
                pawnId,
                productId,
                groupId: availableToConsume.groupId,
            });

            if (newLicenseResponse?.data?.status !== 'success') {
                return setLicensingData({
                    isLoading: false,
                    isValid: false,
                    noLicenseMessage: 'Could not provide a license at this time.',
                });
            }

            return setLicensingData({
                isLoading: false,
                isValid: true,
            });
        }

        return setLicensingData({
            isLoading: false,
            isValid: false,
            noLicenseMessage: noLicenseNoneAvailable,
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (
            ['/lessons', '/resources', '/lessons/v'].includes(location.pathname) &&
            !licensingData.isLoading &&
            !licensingData.isValid
        ) {
            setNoLicenseModal(true);
        } else {
            setNoLicenseModal(false);
        }
    }, [licensingData, location]);

    if (licensingData.isLoading) {
        return <Loader loading />;
    }

    return (
        <LicensingContext.Provider value={{ licensingData, groupingUnitData }}>
            {noLicenseModal && (
                <RenderInBody>
                    <div className={style.noLicense}>
                        <div className={style.backdrop}>
                            <div className="container">
                                <div className={style.content}>
                                    <Html content={licensingData.noLicenseMessage} />
                                    <div className={style.homeButton}>
                                        {/* Outside of React dom tree, so this link does a full page reload */}
                                        <Link to="/">
                                            <button className="btn btn-primary" type="button">
                                                <IconHome spaceRight />
                                                Return Home
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </RenderInBody>
            )}
            {props.children}
        </LicensingContext.Provider>
    );
};

export default LicensingWrapper;
