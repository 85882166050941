import React from 'react';
import { domOnlyProps, FieldBlock } from 'cccisd-redux-form-helpers';
import style from './style.css';

const ActivationTermsAndConds = ({ cccisdPrivacyPolicyUrl, cccisdTermsOfUseUrl, field }) => {
    return (
        <div className="panel panel-default">
            <div className="panel-heading">
                <span style={{ fontSize: '20px', fontWeight: 'bold' }}>3C Privacy Policy and Terms of Use</span>
            </div>
            <div className="panel-body">
                <p>Lions Quest uses the 3C Platform</p>
                <div className={style.terms}>
                    <ul>
                        <li>
                            <a href={cccisdPrivacyPolicyUrl} target="blank">
                                Privacy Policy
                            </a>
                        </li>
                        <li>
                            <a href={cccisdTermsOfUseUrl} target="blank">
                                Terms of Use
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="panel-footer">
                <FieldBlock field={field}>
                    <div className="checkbox">
                        <label>
                            <input type="checkbox" {...domOnlyProps(field)} />I accept the privacy policy and terms of
                            use.
                        </label>
                    </div>
                </FieldBlock>
            </div>
        </div>
    );
};

export default ActivationTermsAndConds;
